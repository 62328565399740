import { classNameHelper } from '../../helpers/componentHelpers';

import './answer-cards.scss';

interface AnswerCardsProps extends React.HTMLAttributes<HTMLDivElement> {
  cards: {
    imgSrc: string,
    isActive: boolean,
    statusType: 'correct' | 'wrong' | 'none',
    isCorrectAnswer: boolean,
    pointsInTime?: number,
    pointsAfterTime?: number
  }[],
  onAnswerClick?: Function
}

function AnswerCards(props: AnswerCardsProps) {
  const {
    className, onAnswerClick, cards,
  } = props;

  return (
    <div className={classNameHelper('answer-card-container', className)}>
      <div className="answer-cards-wrapper">
        <div
          className="d-flex scrollbar-hide answer-card-content"
          role="presentation"
        >
          {cards?.map((card, i) => {
            const index = i;
            return (
              <div
                className={classNameHelper('answer-card', card.isActive && 'active', (card.isActive && card.statusType !== 'none') && card.statusType)}
                key={index}
                onClick={(e) => {
                  const updatedEvent = { ...e, cardIndex: index };
                  if (onAnswerClick) onAnswerClick(updatedEvent);
                }}
                role="presentation"
              >
                <img src={card.imgSrc} alt="cardImg" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

AnswerCards.defaultProps = {
  onAnswerClick: () => { },
};

export default AnswerCards;
