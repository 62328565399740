import { useState, useEffect } from 'react';

import {
  ReactAux, Button, Container, Badge, AnswerReaction,
} from '../../components';
import { genereteImageURL } from '../../helpers/componentHelpers';

import './final.scss';

type FinalpageDateTypes = {
  title?: string,
  buttonText?: string,
  bottomImage?: string,
};

function Final(props: any) {
  const { location } = props;

  const [answerReaction, setAnswerReaction] = useState(true);
  const [finalpageData, setFinalpageData] = useState<FinalpageDateTypes>({});

  useEffect(() => {
    fetch('data/finalpage.json')
      .then((res) => res.json())
      .then(
        (result) => {
          setFinalpageData(result);
        },
        () => {
          // (error) => {
          // TODO Error page or message ?
        },
      );
  }, []);

  return (
    <ReactAux>
      <Container className="final-page">
        <div className="top-box">
          <h3>{finalpageData.title}</h3>
          {location.state.score && <Badge type="success">{location.state.score}</Badge>}
          {location.state.totalTime && <Badge>{location.state.totalTime}</Badge>}
        </div>
        <Button linkProps={{ href: '/game' }}>
          {finalpageData.buttonText}
        </Button>
        <img src={genereteImageURL(finalpageData.bottomImage)} alt="Cover" />
      </Container>
      {answerReaction && (
        <div style={{
          position: 'fixed', width: '100%', height: '16px', bottom: '186px', pointerEvents: 'none',
        }}
        >
          <AnswerReaction
            type="success"
            numberOfIcons={6}
            iconAnimationSeconds={1}
            onAnimationsEnd={() => setAnswerReaction(false)}
          />
        </div>
      )}
    </ReactAux>
  );
}

export default Final;
