import { useEffect, useState } from 'react';
import Sound from 'react-sound';

import { getRndInteger, genereteSoundURL } from '../../helpers/componentHelpers';
import reactionCorrect from '../../icons/reaction-correct.svg';
import reactionWrong from '../../icons/reaction-wrong.svg';

import './answerReaction.scss';

type AnswerReactionProps = {
  type: 'correct' | 'wrong' | 'success',
  numberOfIcons: number,
  iconAnimationSeconds: number,
  onAnimationsEnd(): void,
};

function AnswerReaction(props: AnswerReactionProps) {
  const {
    type, numberOfIcons, iconAnimationSeconds, onAnimationsEnd,
  } = props;

  const [playStatus, setPlayStatus] = useState<'PLAYING' | 'STOPPED'>('PLAYING');
  const [icons, setIcons] = useState<JSX.Element[]>([]);

  const { innerWidth } = window;
  const icon = (type === 'correct' || type === 'success') ? reactionCorrect : reactionWrong;

  useEffect(() => {
    const iconsLenght = icons.length;
    let timeout: any;

    if (iconsLenght < numberOfIcons) {
      timeout = setTimeout(() => {
        icons.push(<img key={iconsLenght} style={{ left: getRndInteger(0, innerWidth), animationDuration: `${iconAnimationSeconds}s` }} src={icon} alt={`reaction-${iconsLenght}`} />);
        setIcons([...icons]);
      }, 100);
    } else {
      timeout = setTimeout(() => {
        onAnimationsEnd();
      }, iconAnimationSeconds * 1000);
    }

    return () => { clearTimeout(timeout); };
  }, [icons]);

  return (
    <div className="answer-reaction">
      {[...icons]}
      <Sound
        url={genereteSoundURL(`reaction-${type}.mp3`)}
        playStatus={playStatus}
        onFinishedPlaying={() => setPlayStatus('STOPPED')}
      />
    </div>
  );
}

export default AnswerReaction;
