import { useState, useEffect } from 'react';
import { Button, Container } from '../../components';

import { genereteImageURL } from '../../helpers/componentHelpers';

import './home.scss';

type HomepageDateTypes = {
  heroImage?: string,
  buttonText?: string,
  bottomImage?: string,
};

function Home() {
  const [homepageData, setHomepageData] = useState<HomepageDateTypes>({});

  useEffect(() => {
    fetch('data/homepage.json')
      .then((res) => res.json())
      .then(
        (result) => {
          setHomepageData(result);
        },
        () => {
          // (error) => {
          // TODO Error page or message ?
        },
      );
  }, []);

  return (
    <Container className="home-page">
      <img src={genereteImageURL(homepageData.heroImage)} alt="Hero" />
      <Button linkProps={{ href: 'game' }}>
        {homepageData.buttonText}
      </Button>
      <img src={genereteImageURL(homepageData.bottomImage)} alt="Cover" />
    </Container>
  );
}

export default Home;
