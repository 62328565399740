import { classNameHelper } from '../../helpers/componentHelpers';

import './question-cards.scss';

interface QestionCardsProps extends React.HTMLAttributes<HTMLDivElement> {
  cards: {
    title: string,
    question: string,
    timer?: JSX.Element,
    answerType?: JSX.Element,
    answerPoints?: JSX.Element,
    rotate?: 'in' | 'out'
  }[],
}

function QestionCards(props: QestionCardsProps) {
  const {
    className,
    cards,
  } = props;

  return (
    <div className={classNameHelper('question-cards-container', className)}>
      {cards?.map((card, i) => {
        const index = i;
        return (
          <div key={index} className={classNameHelper('question-card', card.rotate && `rotate-${card.rotate}`)}>
            <h4 className="question-card-title">{card.title}</h4>
            <div className="question-card-quest"><h3>{card.question}</h3></div>
            <div className="question-card-actions-container">
              {card.timer}
              {card.answerType}
              {card.answerPoints && <div className="question-card-points">{card.answerPoints}</div>}
            </div>

          </div>
        );
      })}
    </div>
  );
}

export default QestionCards;
