import { classNameHelper } from '../../helpers/componentHelpers';

interface ContainerProps extends React.HtmlHTMLAttributes<HTMLDivElement> { }

function Container(props: ContainerProps) {
  const { children, className } = props;

  return <div {...props} className={classNameHelper('container', className)}>{children}</div>;
}

export default Container;
