import { useEffect, useState } from 'react';
import Sound from 'react-sound';

import { genereteSoundURL } from '../../helpers/componentHelpers';
import timer from '../../icons/timer.svg';

import './timerProgress.scss';

type TimerProgressProps = {
  seconds: number,
  onTimeEnd?(): void,
};

let countdownSound: 'PLAYING' | 'STOPPED' = 'STOPPED';

function TimerProgress(props: TimerProgressProps) {
  const { seconds, onTimeEnd } = props;

  const [secondLeft, setSecondLeft] = useState(seconds);

  useEffect(() => {
    let timeout: any;

    if (secondLeft > 0) {
      timeout = setTimeout(() => {
        setSecondLeft(secondLeft - 1);
      }, 1000);

      if (secondLeft < 4) {
        countdownSound = 'PLAYING';
      }
    } else if (onTimeEnd) onTimeEnd();

    return () => {
      clearTimeout(timeout);
      countdownSound = 'STOPPED';
    };
  }, [secondLeft]);

  const progress = 100 - ((secondLeft / seconds) * 100);

  return (
    <>
      <div className="timer-progress-wrapper">
        <img src={timer} alt="timer" />
        <div className="progress-circle-wrapper">
          <div className={`progress-circle${progress > 50 ? ' progress-circle-double' : ''}`}>
            <div className="half-circle" style={{ transform: `rotate(${progress * 3.6}deg)` }} />
            {progress > 50 && <div className="right-circle half-circle" />}
          </div>
        </div>
      </div>
      <Sound
        url={genereteSoundURL('countdown.mp3')}
        playStatus={countdownSound}
        onFinishedPlaying={() => { countdownSound = 'STOPPED'; }}
      />
    </>
  );
}

TimerProgress.defaultProps = {
  onTimeEnd: undefined,
};

export default TimerProgress;
