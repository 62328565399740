import { classNameHelper } from '../../helpers/componentHelpers';

import './badge.scss';

interface BadgeProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  type?: 'label' | 'success' | 'error',
}

function Badge(props: BadgeProps) {
  const { type, children, className } = props;

  return (
    <span className={classNameHelper(`badge badge-${type}`, className)}>
      {children}
    </span>
  );
}

Badge.defaultProps = {
  type: 'label',
};

export default Badge;
