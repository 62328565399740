import {
  BrowserRouter, Switch, Route, Redirect,
} from 'react-router-dom';

import Home from './pages/Home/Home';
import Game from './pages/Game/Game';
import Components from './pages/Components/Components';
import Final from './pages/Final/Final';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to="/home" />
        <Route path="/home" component={Home} />
        <Route path="/game" component={Game} />
        <Route path="/final" component={Final} />
        <Route path="/components" component={Components} />
        <Route component={() => <h2>Error 404</h2>} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
