import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Sound from 'react-sound';

import {
  ReactAux, Container, Button, Header, Badge, BottomActionBar, QuestionCards, AnswerCards,
  AnswerReaction, TimerProgress,
} from '../../components';
import {
  GamePageDateTypes, QuestionCardType,
} from './types/GamePageTypes';
import {
  convertMillisecondsToMinSeconds, genereteImageURL, getRndInteger, genereteSoundURL,
} from '../../helpers/componentHelpers';

import closeIcon from '../../icons/close.svg';
import jokerIcon from '../../icons/joker.svg';
import askIcon from '../../icons/ask.svg';
import balanceIcon from '../../icons/balance.svg';

import './game.scss';

const getCurrQuestionAnswerCards = (
  allCardsArr: any, currQuestAnswersArr: any,
) => {
  const result = allCardsArr.map((answerCard: any) => {
    const currAnswerCard = {
      isActive: false, statusType: 'none', imgSrc: genereteImageURL(answerCard.image), isCorrectAnswer: false,
    } as any;

    currQuestAnswersArr.forEach((questionAnswer: any) => {
      if (answerCard.answerId === questionAnswer.answerId) {
        currAnswerCard.isCorrectAnswer = true;
        currAnswerCard.pointsInTime = questionAnswer.pointsInTime;
        currAnswerCard.pointsAfterTime = questionAnswer.pointsAfterTime;
      }
    });

    return currAnswerCard;
  });

  return result;
};

let timeOutBetweenQuestions: any;
let areInTime = true;
let score = 0;
let startTime = 0;
let helps = {
  joker: { isActive: false, disabled: false },
  '50/50': { isActive: false, disabled: false },
  ask: { isActive: false, disabled: false },
};
let cardFlipSound: 'PLAYING' | 'STOPPED' = 'PLAYING';

const resetSettings = () => {
  areInTime = true;
  score = 0;
  startTime = 0;
  helps = {
    joker: { isActive: false, disabled: false },
    '50/50': { isActive: false, disabled: false },
    ask: { isActive: false, disabled: false },
  };
  cardFlipSound = 'PLAYING';
};

function Game() {
  const [gameData, setGameData] = useState<GamePageDateTypes>({ questions: [] });

  const [questions, setQuestions] = useState<QuestionCardType[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<{
    index: number, question: QuestionCardType
  }>();
  const [answerCards, setAnswerCards] = useState<any>([]);
  const [answerReaction, setAnswerReaction] = useState({ show: false, type: 'correct' });

  useEffect(() => {
    fetch('data/game.json')
      .then((res) => res.json())
      .then(
        (result) => {
          setGameData(result);

          const data = { ...result } as GamePageDateTypes;
          data.questions = data.questions.sort(() => Math.random() - 0.5);

          const firstQuestion = data.questions[0];
          firstQuestion.rotate = 'in';

          if (data.timeTracking) {
            firstQuestion.timer = (
              <TimerProgress
                seconds={firstQuestion.timeSeconds}
                onTimeEnd={() => { areInTime = false; }}
              />
            );
          }

          setQuestions(data.questions);
          setCurrentQuestion({ index: 0, question: firstQuestion });
          startTime = Date.parse(new Date() as any);
        },
        () => {
          // (error) => {
          // TODO Error page or message ?
        },
      );

    return () => {
      if (timeOutBetweenQuestions) clearTimeout(timeOutBetweenQuestions);
      resetSettings();
    };
  }, []);

  useEffect(() => {
    if (gameData && currentQuestion) {
      setAnswerCards(
        getCurrQuestionAnswerCards(gameData.answersCards, currentQuestion.question.correctAnswers),
      );
    }
  }, [currentQuestion]);

  const history = useHistory();
  const goToFinalPage = () => {
    const endTime = Date.parse(new Date() as any);

    history.push('/final', {
      score: gameData.score ? `${gameData.scoreLabel} ${score}` : undefined,
      totalTime: gameData.timeTracking ? `${gameData.timeLabel} ${convertMillisecondsToMinSeconds(endTime - startTime)}` : undefined,
    });
  };

  const onChangeQuestionCard = () => {
    const lastNotShown = questions.findIndex((q) => !q.rotate);

    cardFlipSound = 'PLAYING';

    if (helps.joker.isActive) helps.joker.disabled = true;
    if (helps.ask.isActive) helps.ask.disabled = true;
    if (helps['50/50'].isActive) helps['50/50'].disabled = true;

    if (lastNotShown > 0) {
      const prevQuestionInedex = lastNotShown - 1;
      questions[prevQuestionInedex].rotate = 'out';

      questions[lastNotShown].rotate = 'in';
    } else if (lastNotShown === 0) {
      questions[lastNotShown].rotate = 'in';
    } else if (lastNotShown === -1) {
      goToFinalPage();
    }

    if (lastNotShown > 0) {
      if (gameData.timeTracking) {
        areInTime = true;

        questions[lastNotShown].timer = (
          <TimerProgress
            seconds={questions[lastNotShown].timeSeconds}
            onTimeEnd={() => { areInTime = false; }}
          />
        );
      }

      setCurrentQuestion({ index: lastNotShown, question: questions[lastNotShown] });
    }
  };

  const onClickAnswerCard = (index: number) => {
    const isClickedCard = answerCards.findIndex((card: any) => card.isActive && card.statusType !== 'none');
    if (isClickedCard !== -1) {
      return;
    }

    const currentAnswerClick = answerCards[index];
    const currentQuestionIndex = currentQuestion?.index;

    currentAnswerClick.isActive = true;

    let type = '';

    if (currentAnswerClick.isCorrectAnswer) {
      type = 'correct';

      if (currentQuestionIndex !== undefined) {
        questions[currentQuestionIndex].timer = undefined;
        questions[currentQuestionIndex].answerType = <Badge type="success">correct</Badge>;
      }

      if (gameData.score) {
        let newPoints = 0;

        // TODO Add timeTracking and which points to get if is 'false'
        if (areInTime) {
          newPoints = currentAnswerClick.pointsInTime;
        } else {
          newPoints = currentAnswerClick.pointsAfterTime;
        }

        if (currentQuestionIndex !== undefined) questions[currentQuestionIndex].answerPoints = <Badge type="success">{`+${newPoints} points`}</Badge>;
        score += newPoints;
      }
    } else {
      type = 'wrong';

      if (currentQuestionIndex !== undefined) {
        questions[currentQuestionIndex].timer = undefined;
        questions[currentQuestionIndex].answerType = <Badge type="error">wrong</Badge>;
      }
    }

    currentAnswerClick.statusType = type;

    if (gameData.showFloatingReaction) setAnswerReaction({ show: true, type });

    setAnswerCards([...answerCards]);

    if (gameData.secondsBetweenQuestions) {
      timeOutBetweenQuestions = setTimeout(() => {
        onChangeQuestionCard();
      }, gameData.secondsBetweenQuestions * 1000);
    }
  };

  const onClickBalanceJoker = () => {
    const currentAnswers = currentQuestion?.question.correctAnswers as any;
    const numberCardsToRise = Math.round(answerCards.length / 2) - currentAnswers.length;
    const cardsNotRised = [] as any;

    answerCards.forEach((answCard:any, index:any) => {
      if (answCard.isCorrectAnswer) {
        answerCards[index].isActive = true;
      } else {
        cardsNotRised.push(index);
      }
    });

    for (let i = 0; i < numberCardsToRise; i += 1) {
      const rndIndex = getRndInteger(0, cardsNotRised.length - 1);
      const cardIndexToRIse = cardsNotRised[rndIndex];
      cardsNotRised.splice(rndIndex, 1);
      answerCards[cardIndexToRIse].isActive = true;
    }

    helps['50/50'].isActive = true;
    setAnswerCards([...answerCards]);
  };

  const onClickJoker = () => {
    answerCards.forEach((answCard:any, index:any) => {
      if (answCard.isCorrectAnswer) {
        answerCards[index].isActive = true;
      }
    });

    helps.joker.isActive = true;
    setAnswerCards([...answerCards]);
  };

  return (
    <ReactAux>
      <Header
        leftSide={gameData.topNavigation?.leftLabel}
        centerContent={gameData.score ? <Badge type="success">{`${gameData.scoreLabel} ${score}`}</Badge> : undefined}
        rightSide={gameData.topNavigation?.closeButton ? <Button icon><img src={closeIcon} alt="closeIcon" /></Button> : undefined}
      />

      <Container>
        <div className="game-container">
          <QuestionCards
            cards={questions}
          />
          <Sound
            url={genereteSoundURL('card-flip.mp3')}
            playStatus={cardFlipSound}
            onFinishedPlaying={() => { cardFlipSound = 'STOPPED'; }}
          />
        </div>
      </Container>

      {/* // TODO FIX ANSWER REACTION CONTENT PUSH */}
      <div style={{
        position: 'fixed', width: '100%', height: '16px', bottom: '186px', pointerEvents: 'none',
      }}
      >
        {answerReaction.show && (
          <AnswerReaction
            type={answerReaction.type as any}
            numberOfIcons={6}
            iconAnimationSeconds={1}
            onAnimationsEnd={() => setAnswerReaction({ ...answerReaction, show: false })}
          />
        )}
      </div>

      <AnswerCards
        cards={answerCards}
        onAnswerClick={(e: any) => onClickAnswerCard(e.cardIndex)}
      />

      <BottomActionBar>
        {gameData.help?.joker && (
          <Button
            actionBtn={{
              text: 'Joker',
              iconSrc: jokerIcon,
              type: 'danger',
              isActive: helps.joker.isActive,
            }}
            disabled={helps.joker.disabled}
            onClick={onClickJoker}
          />
        )}
        {gameData.help?.['50/50'] && (
          <Button
            actionBtn={{
              text: '50/50',
              iconSrc: balanceIcon,
              type: 'danger',
              isActive: helps['50/50'].isActive,
            }}
            disabled={helps['50/50'].disabled}
            onClick={onClickBalanceJoker}
          />
        )}
        {gameData.help?.ask && (
          <Button
            actionBtn={{
              text: 'Ask',
              iconSrc: askIcon,
              type: 'danger',
              isActive: helps.ask.isActive,
            }}
            disabled={helps.ask.disabled}
          />
        )}
      </BottomActionBar>
    </ReactAux>
  );
}

export default Game;
