import { useEffect } from 'react';
import Container from '../Container/Container';

import { classNameHelper } from '../../helpers/componentHelpers';

import './bottom-action-bar.scss';

interface BottomActionBarProps extends React.HTMLAttributes<HTMLDivElement> {

}

function BottomActionBar(props: BottomActionBarProps) {
  const { className, children } = props;

  useEffect(() => {
    document.querySelector('body')?.classList.add('bottom-action-bar-rendered');
    return () => document.querySelector('body')?.classList.remove('bottom-action-bar-rendered');
  }, []);

  return (
    <div {...props} className={classNameHelper('bottom-action-bar', className)}>
      <Container>
        <div className="bottom-action-bar-content">
          {children}
        </div>
      </Container>
    </div>
  );
}

export default BottomActionBar;
