import { useState } from 'react';

import {
  Button, Row, Col, Container, ReactAux, Header, Badge, BottomActionBar, TimerProgress,
  AnswerCards, AnswerReaction, QuestionCards,
} from '../../components';

import { getRndInteger, genereteImageURL } from '../../helpers/componentHelpers';

import closeIcon from '../../icons/close.svg';
import jokerIcon from '../../icons/joker.svg';
import askIcon from '../../icons/ask.svg';
import balanceIcon from '../../icons/balance.svg';
import homeIcon from '../../icons/home.svg';
import playIcon from '../../icons/play.svg';

interface QuestionAnswerType {
  answer: number,
  pointsInTime: number
  pointsAfterTime: number
}

interface AnswerCardStatusType {
  isActive: boolean,
  statusType: 'correct' | 'wrong' | 'none',
  imgSrc: string,
  isCorrectAnswer: boolean,
}

interface QuestionCardType {
  title: string,
  question: string,
  timer?: JSX.Element,
  answerType?: JSX.Element,
  answerPoints?: JSX.Element,
  rotate?: 'in' | 'out'
}

// Get data from cards.json
const data = [
  {
    text: 'Joker',
    iconSrc: jokerIcon,
    type: 'danger' as any,
  },
  {
    text: 'Ask',
    iconSrc: askIcon,
    type: 'danger' as any,
  },
  {
    text: '50:50',
    iconSrc: balanceIcon,
    type: 'danger' as any,
  },
];

const answerCardsImgSrc = [
  genereteImageURL('1.svg'), genereteImageURL('2.svg'), genereteImageURL('3.svg'), genereteImageURL('4.svg'),
  genereteImageURL('5.svg'), genereteImageURL('6.svg'), genereteImageURL('7.svg'), genereteImageURL('8.svg'),
];

const currQuestionAnswers = [
  { answer: 1, pointsInTime: 20, pointsAfterTime: 10 },
  { answer: 4, pointsInTime: 15, pointsAfterTime: 5 },
];

const questionCardsDB = [
  {
    title: 'Question 1',
    question: 'I watch my employees on the cameras to make sure they are working I watch my employees on the cameras to make sure they are working',
    // timer: <TimerProgress progress={100} />,
    answerType: <Badge type="success">Correct</Badge>,
    answerPoints: <Badge type="success">20</Badge>,
  } as QuestionCardType,
  {
    title: 'Question 2',
    question: 'text text text',
  } as QuestionCardType,
  {
    title: 'Question 3',
    question: 'text text text',
  } as QuestionCardType,
  {
    title: 'Question 4',
    question: 'text text text',
  } as QuestionCardType,
  {
    title: 'Question 5',
    question: 'text text text',
  } as QuestionCardType,
];

function Components() {
  const [answerReaction, setAnswerReaction] = useState(false);
  const [answerReactionType, setAnswerReactionType] = useState('correct');

  const getCurrQuestionAnswerCards = (
    allCardsImgArr: string[], currQuestAnswersArr: QuestionAnswerType[],
  ) => allCardsImgArr.map((answerCardImg, index) => {
    const currAnswerCard = {
      isActive: false, statusType: 'wrong', imgSrc: answerCardImg, isCorrectAnswer: false,
    } as AnswerCardStatusType;
    currQuestAnswersArr.forEach((questionAnswer: QuestionAnswerType) => {
      if ((questionAnswer.answer - 1) === index) {
        currAnswerCard.statusType = 'correct';
        currAnswerCard.isCorrectAnswer = true;
      }
    });
    return currAnswerCard;
  });

  const [actionBarBtns, setActionBarBtns] = useState(Array.from({ length: 3 },
    () => ({ isActive: false, disabled: false })));

  const [answerCards, setAnswerCards] = useState(
    getCurrQuestionAnswerCards(answerCardsImgSrc, currQuestionAnswers),
  );

  const onClickAnswerCard = (e: any) => {
    const index = e.cardIndex;
    if (answerCards[index].isActive && answerCards[index].isCorrectAnswer && answerCards[index].statusType === 'none') {
      answerCards[index].statusType = 'correct';
    }

    if (answerCards[index].isActive && !answerCards[index].isCorrectAnswer && answerCards[index].statusType === 'none') {
      answerCards[index].statusType = 'wrong';
    }

    if (!answerCards[index].isActive) {
      answerCards[index].isActive = true;
    }

    setAnswerCards([...answerCards]);
  };

  const onClickBalanceJoker = () => {
    let numberCardsToRise = Math.round(answerCards.length / 2);
    const cardsToRised = [];
    const cardsNotRised = [...answerCards];
    currQuestionAnswers.forEach((questionAnswer: QuestionAnswerType) => {
      const cardIndex = questionAnswer.answer - 1;
      numberCardsToRise -= 1;
      cardsToRised.push(answerCards[cardIndex]);

      const indexToRemove = cardsNotRised.map((card) => card.imgSrc)
        .indexOf(answerCards[cardIndex].imgSrc);
      cardsNotRised.splice(indexToRemove, 1);
    });

    for (let i = 0; i < numberCardsToRise; i += 1) {
      const randomRisedCardIndex = getRndInteger(1, cardsNotRised.length);
      cardsToRised.push(cardsNotRised[randomRisedCardIndex - 1]);
      cardsNotRised.splice(randomRisedCardIndex - 1, 1);
    }

    cardsToRised.forEach((cardToRise) => {
      answerCards.forEach((ansCard, index) => {
        if (ansCard.imgSrc === cardToRise.imgSrc) {
          answerCards[index].isActive = true;
          answerCards[index].statusType = 'none';
        }
      });
    });
    setAnswerCards([...answerCards]);
  };

  const [questionCards, setQestionCards] = useState(questionCardsDB);

  const onChangeQuestionCard = () => {
    const lastNotShown = questionCards.findIndex((q) => !q.rotate);
    if (lastNotShown > 0) {
      const prevQuestionInedex = lastNotShown - 1;
      questionCards[prevQuestionInedex].rotate = 'out';
      questionCards[lastNotShown].rotate = 'in';
    } else if (lastNotShown === 0) {
      questionCards[lastNotShown].rotate = 'in';
    }
    setQestionCards([...questionCards]);
  };

  return (
    <ReactAux>
      <Header
        leftSide="Level 1"
        centerContent="1 - Need for Certainty"
        rightSide={<Button icon><img src={closeIcon} alt="closeIcon" /></Button>}
      />

      <Container style={{ paddingBottom: '175px' }}>
        <h1>Components:</h1>
        <br />
        <br />
        <br />
        <h2>Button:</h2>
        <br />
        <Button>button</Button>
        <Button
          size="big"
          linkProps={{
            // set Link to or native link href
            href: 'https://www.google.com/',
            // set target blank on both
            isTargetBlank: true,
            // render native link
            isNativeLink: true,
            // set title on both
            title: 'testbtn',
            // set on click function on both. On native link default is prevented
            // onClick: () => { console.log('test'); },
          }}
        >
          Link btn
        </Button>
        <br />
        <br />
        <Button size="small">button</Button>
        <Button size="small" linkProps={{ href: 'home', isTargetBlank: true }} onClick={() => { console.log('test'); }}>Link btn</Button>
        <br />
        <br />
        <Button size="small" disabled>button</Button>
        <br />
        <br />
        <Button icon><img src={closeIcon} alt="closeIcon" /></Button>
        <Button icon size="small" linkProps={{ href: 'home' }} onClick={() => { console.log('test'); }}>
          <img src={closeIcon} alt="closeIcon" />
          <span>Close Link</span>
        </Button>

        <h3>Action buttons</h3>
        <br />
        <div className="d-flex align-items-center">
          <Button
            actionBtn={{
              text: 'Home',
              iconSrc: homeIcon,
              type: 'white',
              isActive: false,
            }}
            linkProps={{ href: 'home', isTargetBlank: true }}
            onClick={() => { console.log('test'); }}
          />

          <Button
            actionBtn={{
              text: 'Joker',
              iconSrc: jokerIcon,
              type: 'danger',
              isActive: false,
            }}
          />

          <Button
            actionBtn={{
              iconSrc: playIcon,
            }}
          />
        </div>

        <br />
        <br />

        <h2>Typography:</h2>
        <br />
        <h1>H1 LEARM</h1>
        <h2>H2 THE SIX human needs THAT DETERMINATES EVERYTHING THET WE DO</h2>
        <h3>H3 The model</h3>
        <h4>H4 Title</h4>
        <p>
          P The need for certainty means that you are continually striving to feel
          safe and secure. You value predictability and a sense of comfort. This gives
          you peace-of-mind and helps ward off stress, anxiety, and worry.
        </p>
        <p className="small-text">Small text</p>
        <br />
        <br />
        <br />

        <h2>Grid:</h2>
        <br />
        <Row>
          <Col>col</Col>
          <Col>col</Col>
          <Col>col</Col>
        </Row>
        <br />
        <br />
        <br />

        <h2>Badge:</h2>
        <br />
        <Badge>LABEL</Badge>
        <Badge type="success">SUCCESS</Badge>
        <Badge type="error">error</Badge>
        <Badge type="success">SCORE 1045</Badge>
        <br />
        <br />
        <br />

        <h2>TimerProgress:</h2>
        <br />
        <TimerProgress seconds={90} />
        <br />
        <br />
        <br />

        <h2>AnswerReaction:</h2>
        <br />
        <Button size="small" disabled={answerReaction} onClick={() => setAnswerReaction(true)}>{`Trigger Reaction ${answerReactionType}`}</Button>
        <select disabled={answerReaction} onChange={(e) => setAnswerReactionType(e.target.value)}>
          <option value="correct">correct</option>
          <option value="wrong">wrong</option>
        </select>
        <br />
        <br />
        <br />

        <Button
          onClick={() => {
            onChangeQuestionCard();
          }}
        >
          Card rotate
        </Button>
        <QuestionCards
          cards={questionCards}
        />
      </Container>

      {answerReaction && (
        <AnswerReaction
          type={answerReactionType as any}
          numberOfIcons={6}
          iconAnimationSeconds={1}
          onAnimationsEnd={() => setAnswerReaction(false)}
        />
      )}

      <AnswerCards
        onAnswerClick={onClickAnswerCard}
        cards={answerCards}
      />
      <BottomActionBar>
        {data.map((el, i) => {
          const index = i; return (
            <Button
              key={index}
              actionBtn={
                {
                  ...el,
                  isActive: actionBarBtns[index].isActive,
                }
              }
              disabled={actionBarBtns[index].disabled}
              onClick={() => {
                if (index === 2 && !actionBarBtns[index].isActive) {
                  onClickBalanceJoker();
                }
                actionBarBtns[index].isActive = true;
                setActionBarBtns([...actionBarBtns]);
              }}
            />
          );
        })}
      </BottomActionBar>
    </ReactAux>
  );
}

export default Components;
