import { Link } from 'react-router-dom';
import { classNameHelper } from '../../helpers/componentHelpers';

import './button.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'big' | 'small',
  icon?: boolean,
  linkProps?:{
    href?: string,
    title?: string,
    isTargetBlank?: boolean,
    isNativeLink?: boolean,
  }
  actionBtn?:{
    isActive? : boolean,
    iconSrc?:string,
    text?:string,
    type?: 'white' | 'danger'
  }
}

function Button(props: ButtonProps) {
  const {
    children, size, linkProps, icon, className, actionBtn,
  } = props;

  const propsCopy = { ...props };
  const componentClassName = {
    className: classNameHelper(
      'btn',
      !actionBtn && 'btn-primary',
      !actionBtn && `btn-${size}`,
      icon && 'btn-icon',
      actionBtn && 'btn-cta',
      actionBtn && actionBtn.type && `btn-cta-${actionBtn.type}`,
      actionBtn && actionBtn.isActive && 'active',
      className,
    ),
  };

  delete propsCopy.linkProps;
  delete propsCopy.size;
  delete propsCopy.icon;
  delete propsCopy.children;
  delete propsCopy.linkProps;
  delete propsCopy.actionBtn;

  const renderLink = (
    linkAttr:any,
    linkChildren: any,
    actionBtnProps:any, otherProps:any,
  ) => (linkAttr.isNativeLink ? (
    <a
      href={linkAttr.href || '#'}
      title={linkAttr.title || ''}
      {...otherProps}
      {...linkAttr.isTargetBlank && { target: '_blank' }}
      {...otherProps.onClick && { onClick: (e) => { e.preventDefault(); otherProps.onClick(); } }}
      {...componentClassName}
    >
      {actionBtnProps
        ? (
          <>
            {actionBtnProps.iconSrc && <img src={actionBtnProps.iconSrc} alt="img" />}
            {actionBtnProps.text && <span>{actionBtnProps.text}</span>}
          </>
        )
        : linkChildren}
    </a>
  ) : (
    <Link
      to={linkAttr.href || '#'}
      {...otherProps}
      {...linkAttr.title && { title: linkAttr.title }}
      {...linkAttr.isTargetBlank && { target: '_blank' }}
      {...linkAttr.onClick && { onClick: linkAttr.onClick }}
      {...componentClassName}
    >
      {actionBtnProps
        ? (
          <>
            {actionBtnProps.iconSrc && <img src={actionBtnProps.iconSrc} alt="img" />}
            {actionBtnProps.text && <span>{actionBtnProps.text}</span>}
          </>
        )
        : linkChildren}
    </Link>
  ));

  return (
    linkProps ? (
      renderLink(linkProps, children, actionBtn, propsCopy)
    )
      : (
        <button type="button" {...propsCopy} {...componentClassName}>
          {actionBtn
            ? (
              <>
                {actionBtn.iconSrc && <img src={actionBtn.iconSrc} alt="img" />}
                {actionBtn.text && <span>{actionBtn.text}</span>}
              </>
            )
            : children}
        </button>
      )
  );
}

Button.defaultProps = {
  size: 'big',
  icon: false,
  linkProps: undefined,
  actionBtn: undefined,
};

export default Button;
