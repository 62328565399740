export const classNameHelper = (...classes: any[]) => classes.filter((c) => !!c).join(' ');

export const genereteImageURL = (img: any) => `${process.env.PUBLIC_URL}/images/${img}`;

export const genereteSoundURL = (name: any) => `${process.env.PUBLIC_URL}/sounds/${name}`;

export const getRndInteger = (min: number, max: number) => Math.floor(
  Math.random() * (max - min + 1),
) + min;

export const convertMillisecondsToMinSeconds = (milliseconds: number) => {
  let seconds = milliseconds / 1000;
  const result = (seconds - (seconds %= 60)) / 60 + (seconds > 9 ? ':' : ':0') + seconds;

  return result;
};
