import { useEffect } from 'react';
import Container from '../Container/Container';
import { classNameHelper } from '../../helpers/componentHelpers';

import './header.scss';

interface HeaderProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  leftSide?: string | JSX.Element
  centerContent?: string | JSX.Element
  rightSide?: string | JSX.Element
}

function Header(props: HeaderProps) {
  const {
    leftSide, centerContent, rightSide, className,
  } = props;

  const copyProps = { ...props };
  delete copyProps.leftSide;
  delete copyProps.centerContent;
  delete copyProps.rightSide;

  // add body class to set spacing for header
  useEffect(() => {
    document.querySelector('body')?.classList.add('header-rendered');
    return () => { document.querySelector('body')?.classList.remove('header-rendered'); };
  }, []);

  return (
    <header {...copyProps} className={classNameHelper('header', className)}>
      <Container>
        <div className="header-content">
          <div>{leftSide}</div>
          <div className="header-center-container">{centerContent}</div>
          <div>{rightSide}</div>
        </div>
      </Container>
    </header>
  );
}

Header.defaultProps = {
  leftSide: '',
  centerContent: '',
  rightSide: '',
};

export default Header;
